import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import Layout from '/src/components/layout'
import { ProductView, RelatedProducts } from '/src/components/commerce'
import { SendEnquiryBlock } from '/src/components/common'
import { removeTags, trimMetaDescription } from '/src/util'

export const Head = ({ data }) => {
  const [product] = data.allCommerceProduct.edges
  const hasBody = useMemo(() => product.node.body && product.node.body.processed ? true : false, [product.node.body && product.node.body.processed])

  return (
    <>
      <title>{product.node.title} | Metalcorp</title>
      {hasBody && <meta name="description" content={trimMetaDescription(removeTags(product.node.body.processed))} />}
      {hasBody && <meta name="og:description" property="og:description" content={trimMetaDescription(removeTags(product.node.body.processed))} />}
      <meta property="og:image" content="https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/opengraph.png" />
    </>
  )
}


export default ({ data }) => {
  const [product] = data.allCommerceProduct.edges

  if (!product) {
    return 'no product found'
  }

  return (
    <Layout>
      <Row className="mb-5">
        <Col xs={12}>
          <ProductView {...product.node} />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs={12}>
          <SendEnquiryBlock />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={12}>
          <RelatedProducts {...product.node.relationships} />
        </Col>
      </Row>
    </Layout>
  )
}

// filter product variations status eq true
export const query = graphql`
  query ($id: String!) {
    allCommerceProduct(filter: {drupal_id: {eq: $id}}) {
      edges {
        node {
          title
          body {
            processed
          }
          field_product_min_purchase
          field_product_features {
            processed
          }
          field_product_optional_features {
            processed
          }
          field_product_disclaimer {
            processed
          }
          path {
            alias
          }
          dsap_product_ids {
            product_id
            dsap_product_id
          }
          attributes_display {
            attribute_brand {
              display
              name
              weight
            }
            attribute_diameter_outside {
              display
              name
              weight
            }
            attribute_finish {
              display
              weight
              name
            }
            attribute_grade {
              display
              name
              weight
            }
            attribute_length {
              display
              name
              weight
            }
            attribute_nominal_bore {
              display
              name
              weight
            }
            attribute_size {
              display
              name
              weight
            }
            attribute_size1 {
              display
              name
              weight
            }
            attribute_size2 {
              display
              name
              weight
            }
            attribute_thickness {
              display
              name
              weight
            }
            attribute_wall_thickness {
              display
              name
              weight
            }
            attribute_width {
              display
              name
              weight
            }
          }
          relationships {
            field_product_images {
              relationships {
                field_media_image_2 {
                  url
                  gatsbyImage(
                    width: 162
                    quality: 100
                    placeholder: DOMINANT_COLOR
                    layout: FIXED
                    fit: COVER
                    formats: AUTO
                  )
                }
              }
            }
            variations {
              ... on product_variation__fencing {
                id
                title
                status
                drupal_id
                dimensions {
                  height
                  length
                  width
                  unit
                }
                field_base_uom
                field_sales_uom
                field_prod_variation_reference
                price {
                  formatted
                  number
                }
                weight {
                  number
                  unit
                }
                product_dimensions {
                  height
                  length
                  unit
                  width
                }
                relationships {
                  attribute_finish {
                    name
                    id
                  }
                  attribute_length {
                    field_value
                    id
                    field_uom
                    name
                  }
                  attribute_size1 {
                    id
                    name
                  }
                  attribute_size2 {
                    id
                    name
                  }
                  attribute_thickness {
                    id
                    field_value
                    name
                  }
                  attribute_width {
                    field_value
                    id
                    name
                  }
                  field_material_group {
                    name
                    id
                  }
                }
                internal {
                  type
                }
              }
              ... on product_variation__tube_and_pipe {
                id
                title
                status
                drupal_id
                dimensions {
                  height
                  length
                  width
                  unit
                }
                field_base_uom
                field_sales_uom
                field_prod_variation_reference
                price {
                  formatted
                  number
                }
                weight {
                  number
                  unit
                }
                product_dimensions {
                  height
                  length
                  unit
                  width
                }
                relationships {
                  attribute_brand {
                    id
                    name
                    field_string_value
                  }
                  attribute_diameter_outside {
                    id
                    name
                    field_value
                  }
                  attribute_finish {
                    id
                    name
                  }
                  attribute_grade {
                    id
                    name
                    field_string_value
                  }
                  attribute_length {
                    id
                    field_value
                    field_uom
                    name
                  }
                  attribute_size {
                    name
                    id
                  }
                  attribute_thickness {
                    id
                    field_value
                    name
                  }
                  field_material_group {
                    name
                    id
                  }
                }
                internal {
                  type
                }
              }
              ... on product_variation__hot_rolled_structural_steel {
                id
                title
                status
                drupal_id
                dimensions {
                  height
                  length
                  width
                  unit
                }
                field_base_uom
                field_sales_uom
                field_prod_variation_reference
                price {
                  formatted
                  number
                }
                weight {
                  number
                  unit
                }
                product_dimensions {
                  height
                  length
                  unit
                  width
                }
                relationships {
                  attribute_grade {
                    id
                    name
                    field_string_value
                  }
                  attribute_length {
                    field_value
                    id
                    field_uom
                    name
                  }
                  attribute_size {
                    id
                    name
                  }
                  attribute_size2 {
                    id
                    name
                    weight
                  }
                  attribute_thickness {
                    id
                    field_value
                    name
                  }
                  field_material_group {
                    name
                    id
                  }
                }
                internal {
                  type
                }
              }
              ... on product_variation__general {
                id
                title
                status
                drupal_id
                dimensions {
                  height
                  length
                  width
                  unit
                }
                field_base_uom
                field_sales_uom
                field_prod_variation_reference
                price {
                  formatted
                  number
                }
                weight {
                  number
                  unit
                }
                product_dimensions {
                  height
                  length
                  unit
                  width
                }
                relationships {
                  attribute_brand {
                    id
                    name
                    field_string_value
                  }
                  attribute_finish {
                    id
                    name
                  }
                  attribute_length {
                    id
                    field_value
                    field_uom
                    name
                  }
                  attribute_size1 {
                    id
                    name
                  }
                  attribute_size2 {
                    id
                    name
                  }
                  attribute_thickness {
                    id
                    field_value
                    name
                  }
                  attribute_width {
                    id
                    field_value
                    name
                  }
                  field_material_group {
                    id
                    name
                  }
                }
                internal {
                  type
                }
              }
              ... on product_variation__structural_tube_pipe {
                id
                status
                dimensions {
                  height
                  length
                  width
                  unit
                }
                drupal_id
                dsap_product_id
                field_base_uom
                field_prod_variation_reference
                field_sales_uom
                title
                internal {
                  type
                }
                price {
                  formatted
                  number
                }
                product_dimensions {
                  height
                  length
                  unit
                  width
                }
                relationships {
                  attribute_brand {
                    id
                    name
                    field_string_value
                  }
                  attribute_diameter_outside {
                    field_value
                    id
                    name
                  }
                  attribute_finish {
                    id
                    name
                  }
                  attribute_grade {
                    id
                    field_string_value
                    name
                  }
                  attribute_length {
                    field_value
                    id
                    name
                  }
                  attribute_nominal_bore {
                    id
                    name
                    field_value
                  }
                  attribute_thickness {
                    id
                    field_value
                    name
                  }
                  attribute_wall_thickness {
                    name
                    id
                    field_string_value
                  }
                }
              }
            }
            field_related_products {
              ... on product__cattle_handling_equipment {
                id
                relationships {
                  field_product_images {
                    relationships {
                      field_media_image_2 {
                        publicUrl
                        gatsbyImage(
                          width: 162
                          fit: COVER
                          formats: AUTO
                          layout: FIXED
                          placeholder: DOMINANT_COLOR
                          quality: 100
                        )
                      }
                    }
                  }
                }
                path {
                  alias
                }
                title
              }
              ... on product__fencing {
                id
                relationships {
                  field_product_images {
                    relationships {
                      field_media_image_2 {
                        publicUrl
                        gatsbyImage(
                          fit: COVER
                          layout: FIXED
                          placeholder: DOMINANT_COLOR
                          quality: 100
                          width: 162
                          formats: AUTO
                        )
                      }
                    }
                  }
                }
                path {
                  alias
                }
                title
              }
              ... on product__goat_equipment {
                id
                path {
                  alias
                }
                title
                relationships {
                  field_product_images {
                    relationships {
                      field_media_image_2 {
                        gatsbyImage(
                          fit: COVER
                          layout: FIXED
                          placeholder: DOMINANT_COLOR
                          quality: 100
                          width: 162
                          formats: AUTO
                        )
                      }
                    }
                  }
                }
              }
              ... on product__horse_equipment {
                id
                path {
                  alias
                }
                title
                relationships {
                  field_product_images {
                    relationships {
                      field_media_image_2 {
                        id
                        gatsbyImage(
                          layout: FIXED
                          formats: AUTO
                          fit: COVER
                          width: 162
                          quality: 100
                          placeholder: DOMINANT_COLOR
                        )
                      }
                    }
                  }
                }
              }
              ... on product__merchant_bar {
                id
                path {
                  alias
                }
                relationships {
                  field_product_images {
                    relationships {
                      field_media_image_2 {
                        gatsbyImage(
                          fit: COVER
                          placeholder: DOMINANT_COLOR
                          quality: 100
                          layout: FIXED
                          width: 162
                          formats: AUTO
                        )
                      }
                    }
                  }
                }
                title
              }
              ... on product__precision_tube_pipe {
                id
                title
                path {
                  alias
                }
                relationships {
                  field_product_images {
                    relationships {
                      field_media_image_2 {
                        gatsbyImage(
                          fit: COVER
                          layout: FIXED
                          placeholder: DOMINANT_COLOR
                          quality: 100
                          width: 162
                        )
                      }
                    }
                  }
                }
              }
              ... on product__other_accessories {
                id
                title
                path {
                  alias
                }
                relationships {
                  field_product_images {
                    relationships {
                      field_media_image_2 {
                        gatsbyImage(
                          fit: COVER
                          layout: FIXED
                          quality: 100
                          placeholder: DOMINANT_COLOR
                          width: 162
                        )
                      }
                    }
                  }
                }
              }
              ... on product__welding {
                id
                path {
                  alias
                }
                title
                relationships {
                  field_product_images {
                    relationships {
                      field_media_image_2 {
                        gatsbyImage(
                          formats: AUTO
                          fit: COVER
                          layout: FIXED
                          quality: 100
                          placeholder: DOMINANT_COLOR
                          width: 162
                        )
                      }
                    }
                  }
                }
              }
              ... on product__wire {
                id
                path {
                  alias
                }
                title
                relationships {
                  field_product_images {
                    relationships {
                      field_media_image_2 {
                        gatsbyImage(
                          fit: COVER
                          formats: AUTO
                          layout: FIXED
                          quality: 100
                          placeholder: DOMINANT_COLOR
                          width: 162
                        )
                      }
                    }
                  }
                }
              }
              ... on product__beam {
                id
                path {
                  alias
                }
                title
                relationships {
                  field_product_images {
                    relationships {
                      field_media_image_2 {
                        publicUrl
                        gatsbyImage(
                          width: 162
                          quality: 100
                          placeholder: DOMINANT_COLOR
                          layout: FIXED
                          formats: AUTO
                          fit: COVER
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
